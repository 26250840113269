<template>

  <div>
    <!-- Filters -->

    <modules-list-filters
      :description-filter.sync="descriptionFilter"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="$ability.can('C', 'Modules & Menus')"
                variant="primary"
                :to="{ name: 'system-settings-modules-create' }"
              >
                <span class="text-nowrap">Add Module</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refModuleListTable"
        class="position-relative"
        :items="fetchModules"
        responsive
        :fields="tableColumns"
        primary-key="moduleId"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status"
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatus(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="$ability.can('V', 'Modules & Menus')"
              :to="{ name: 'system-settings-modules-view', params: { id: data.item.moduleId } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$ability.can('E', 'Modules & Menus') && data.item.status !== 'D'"
              :to="{ name: 'system-settings-modules-edit', params: { id: data.item.moduleId } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$ability.can('D', 'Modules & Menus') && data.item.status !== 'D'"
              @click="deleteModule({ moduleId: data.item.moduleId, operation: 'D' })"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

        <!-- Column: Show Details -->
        <template #cell(show_details)="row">
          <b-button
            v-ripple.400="'rgba(53, 197, 240, 0.15)'"
            variant="flat-info"
            class="btn-icon rounded-circle"
            size="sm"
            @click="row.toggleDetails"
          >
            <feather-icon :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
          </b-button>
        </template>

        <!-- Column: Row Details -->
        <template #row-details="data">
          <menus-list
            :menus="data.item.menus"
            @refetch-data="refetchData"
          />
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ModulesListFilters from './ModulesListFilters.vue'
import useModulesList from './useModulesList'
import moduleStoreModule from '../moduleStoreModule'
import MenusList from '../../menus/menus-list/MenusList.vue'
import { resolveStatus, resolveStatusVariant } from '@/utils/common-functions/resolvers'
import { statusOptions } from '@/utils/computed-properties/system-codes/systemCodesComputedProperties'

export default {
  components: {
    ModulesListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    MenusList,
  },
  directives: {
    Ripple,
  },
  setup() {
    const MODULE_APP_STORE_MODULE_NAME = 'app-module'

    // Register module
    if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.registerModule(MODULE_APP_STORE_MODULE_NAME, moduleStoreModule)

    const {
      fetchModules,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refModuleListTable,
      refetchData,

      // Extra Filters
      descriptionFilter,
      statusFilter,

      deleteModule,
    } = useModulesList()

    return {
      fetchModules,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refModuleListTable,
      refetchData,

      // UI
      resolveStatus,
      resolveStatusVariant,

      statusOptions,

      // Extra Filters
      descriptionFilter,
      statusFilter,

      deleteModule,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
