import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import moduleStoreModule from '../moduleStoreModule'
// import useSystemCodes from '@/views/system-settings/system-codes/useSystemCodes'

export default function useModulesList() {
  const MODULE_APP_STORE_MODULE_NAME = 'app-module'

  // Register module
  if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.registerModule(MODULE_APP_STORE_MODULE_NAME, moduleStoreModule)

  // Use toast
  const toast = useToast()

  const refModuleListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'actions', sortable: true },
    { key: 'description', sortable: true },
    { key: 'status', sortable: true },
    {
      key: 'show_details',
      sortable: false,
      label: '',
      class: 'text-right',
    },
  ]
  const perPage = ref(10)
  const totalRecords = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('moduleId')
  const isSortDirDesc = ref(true)
  const descriptionFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refModuleListTable.value ? refModuleListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRecords.value,
    }
  })

  const refetchData = () => {
    refModuleListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, descriptionFilter, statusFilter], () => {
    refetchData()
  })

  const fetchModules = (ctx, callback) => {
    store
      .dispatch('app-module/fetchModules', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        description: descriptionFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const records = response.data.data

        callback(records)
        totalRecords.value = records.length
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Modules list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteModule = async deleteParams => {
    await store.dispatch('app-module/procModule', deleteParams)
    refetchData()
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // Call System Codes
  // const { callStatus } = useSystemCodes()
  // callStatus()

  return {
    fetchModules,
    tableColumns,
    perPage,
    currentPage,
    totalRecords,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refModuleListTable,

    refetchData,

    // Extra Filters
    descriptionFilter,
    statusFilter,

    deleteModule,
  }
}
